/* eslint-disable no-unused-vars */
export const API_URL = 'https://nodejs-template.codeaplha.biz';

export enum ApiPath {
  USER = '/api/admin/accounts',
  ME = '/api/admin/me',
  LOGIN = '/api/auth/login',
  MAKERS= '/api/public/master-makers',
  REGION='/api/public/regions',
  DETAIL_USED_CAR='/api/admin/used-cars',
  LIST_USED_CAR='/api/admin/used-cars',
}
