import { notifyMessage } from "./message";

export const formatPrice = (price) =>{
    return Number(price).toLocaleString()
}

export const getUuid = (price) =>{
    return Number(price).toLocaleString()
}

export const getErrorMsgFromResponse = (e) => {
    return e?.response?.data?.errors
      ? e?.response?.data?.errors[0]?.message
      : notifyMessage.sever_error;
  };

  export const removeSpecialCharacters = (value) => {
    return value?.replace(/[^0-9]/g, '');
  };
  

  export const formatNumber = (value) => {
    if (typeof value === 'number') {
      return value.toLocaleString('en-US');
    } else {
      const valueCustom = value
        ?.replace(/[^\w０-９]/g, '')
        ?.replace(/[^\d０-９.%]/g, '')
        ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1');
      return valueCustom;
    }
  };