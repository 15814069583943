import { createContext, useCallback, useEffect, useState } from 'react';
import { LoadingOutlined, notification, Spin } from 'core-ui';

import { Navigate, useLocation } from 'react-router-dom';
import { RoutePath } from 'src/enums/routePath';
import { isLogged } from 'src/services/auth';
import { store } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { notifyMessage } from 'src/constants/message';
import { authMiddleware } from 'src/router/midlewareAuth';
import { fetchInit } from 'src/services/init';
import authInfoSlice, { AuthState } from 'src/redux/authInfoSlice';

export const LoadingContext = createContext({
  isLoading: false,
  turnOnLoading: () => {},
  turnOffLoading: () => {}
});

const LoadingProvider = ({ children }: { children: React.ReactElement }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setAuthInfo } = authInfoSlice.actions;
  const auth: any = useSelector((state: AuthState) => state?.auth?.user);

  const turnOnLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  const turnOffLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (isLogged && !auth?.name) {
      fetchInit()
        .then((data) => {
          if (data) {
            store.dispatch(setAuthInfo(data));
          }
        })
        .catch((error) => {
          notification.error({
            message: notifyMessage?.sever_error
          });
        });
    }
  }, [location]);

  if (!authMiddleware(location as any)) {
    return <Navigate to={RoutePath.ACCOUNT_MANAGEMENT} replace />;
  }

  return (
    <LoadingContext.Provider
      value={{ turnOnLoading, turnOffLoading, isLoading }}
    >
      {isLoading && (
        <div className="fixed top-0 left-0 w-[100vw] h-[100vh] z-50 bg-[rgba(0,0,0,0.4)] flex justify-center items-center">
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 50, color: 'white' }} spin />
            }
          />
        </div>
      )}
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
