import { Paper, InputBase, Button, Pagination } from '@mui/material';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { TITLE } from '../../constants/title';
import AccountCUD from './AccountCUD';
import AccountDelete from './AccountDelete';
import PaginationCustom from 'src/components/pagination';
import {
  createUser,
  deleteUser,
  getUsers,
  updateUser
} from 'src/services/user';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { defaultParams } from 'src/constants/apiRequestParams';
import { notification } from 'core-ui';
import { getErrorMsgFromResponse } from 'src/constants/function';
import { notifyMessage } from 'src/constants/message';
import LoadingSpin from 'src/components/loading-spin';

export const TypeAction = {
  CREATE: 'create',
  EDIT: 'edit',
  DETAIL: 'detail'
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#C7D7FF',
    color: '#1C1E21',
    borderRight: '1px solid #f5f5f5',
    '&:first-of-type': {
      borderTopLeftRadius: '12px'
    },
    '&:last-of-type': {
      borderTopRightRadius: '12px'
    },
    
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: '1px solid #f5f5f5',
    padding: '12px 16px',
    borderRadius: '10px'
  }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

export const StyledSelect = styled(Select)(`
    font-size: 14px;  
`);

export function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular
  };
}

function createData(name, email, action) {
  return { name, email, action };
}

const rows = [
  createData('山本 和久', 'tienlapspktnd@gmail.com'),
  createData('木村 あゆみ', 'manhhachkt08@gmail.com'),
  createData('山田 太郎', 'ckctm12@gmail.com'),
  createData('佐藤 幸一郎', 'vuhaithuongnute@gmail.com'),
  createData('高橋 麻菜', 'nvt.isst.nute@gmail.com'),
  createData('石井 昌弘', 'thuhang.nute@gmail.com'),
  createData('石井 昌弘', 'thuhang.nute@gmail.com'),
  createData('石井 昌弘', 'thuhang.nute@gmail.com'),
  createData('石井 昌弘', 'thuhang.nute@gmail.com'),
  createData('石井 昌弘', 'thuhang.nute@gmail.com'),
  createData('石井 昌弘', 'thuhang.nute@gmail.com'),
  createData('石井 昌弘', 'thuhang.nute@gmail.com'),
  createData('石井 昌弘', 'thuhang.nute@gmail.com'),
  createData('石井 昌弘', 'thuhang.nute@gmail.com'),
  createData('石井 昌弘', 'thuhang.nute@gmail.com')
];

export default function AccountManagement(props) {
  const queryClient = useQueryClient();

  const [params, setParams] = React.useState(defaultParams);

  const [dataCustom, setDataCustom] = React.useState([]);

  const [typeAction, setTypeAction] = React.useState();

  const [detailAccount, setDetailAccount] = React.useState({});

  const [open, setOpen] = React.useState(false);

  const [openDelete, setOpenDelete] = React.useState(false);

  const { register, handleSubmit, setValue, errors } = useForm();

  const currentPagePagination = params?.page === 0 ? 1 : params?.page;

  const onSearch = (data) => {
    const dataCustom = {
      name: data?.name?.trim() ?? '',
      email: data?.email?.trim() ?? ''
    };
    setParams((params) => {
      return {
        ...params,
        page: 1,
        name: dataCustom?.name,
        email: dataCustom?.email
      };
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTypeAction('');
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDeleteAccount = () => {
    mutateDeleteAccount(
      { id: detailAccount?.id },
      {
        onSuccess: () => {
          notification.success({
            message: notifyMessage.delete_success
          });
          params?.page !== 1 &&
            dataCustom.length === 1 &&
            setPage(params?.page ? params?.page - 1 : 1);
          queryClient.invalidateQueries({
            queryKey: ['getDataListAccount']
          });
          handleCloseDelete();
        },
        onError: (e) => {
          e?.response?.status === 500
            ? notification.error({
                message: notifyMessage?.sever_error
              })
            : e?.response?.status === 404
              ? notification.error({
                  message: e?.response?.data?.errors
                })
              : notification.error({
                  message: getErrorMsgFromResponse(e)
                });
        }
      }
    );
  };

  // api call create account
  const { mutate: mutateCreateAccount, isSuccess: isSuccessCreate } =
    useMutation(createUser);

  // api call edit account
  const { mutate: mutateEditAccount, isSuccess: isSuccessEdit } =
    useMutation(updateUser);

  // api delete  account
  const { mutate: mutateDeleteAccount, isSuccess: isSuccessDelete } =
    useMutation(deleteUser);

  const {
    data: dataLisAccount,
    isLoading,
    isFetching
  } = useQuery(
    [
      'getDataListAccount',
      params?.page,
      params?.limit,
      isSuccessCreate,
      isSuccessEdit,
      isSuccessDelete,
      params
    ],
    () =>
      getUsers({
        page: params?.page,
        limit: params?.limit,
        name: params?.name?.trim() ?? '',
        email: params?.email?.trim() ?? ''
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setParams((params) => {
          return {
            ...params,
            page: data?.meta?.page,
            limit: data?.meta?.limit,
            total: data?.meta?.total
          };
        });
        setDataCustom(data?.data);
      },
      onError: (e) => {
        e?.response?.status === 500
          ? notification.error({
              message: notifyMessage?.sever_error
            })
          : notification.error({
              message: getErrorMsgFromResponse(e)
            });
      }
    }
  );

  const rowLength = dataLisAccount?.meta?.total;

  const currentCount =
    params?.limit > params?.total ? 1 : Math.ceil(rowLength / params?.limit);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end'
        }}
      >
        <form className="w-[74%]" onSubmit={handleSubmit(onSearch)}>
          <div
            style={{
              display: 'flex',
              alignItems: 'end',
              height: 38,
              marginTop: 30,
              gap: 15
            }}
          >
            <div className="w-full">
              <Paper sx={{ mb: 1, fontWeight: 700 }}>名前</Paper>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid rgba(0, 0, 0, 0.15)',
                  width: '100%'
                }}
              >
                <InputBase
                  sx={{
                    ml: 1,
                    flex: 1
                  }}
                  placeholder="名前を入力"
                  {...register('name')}
                />
              </Paper>
            </div>
            <div className="w-full">
              <Paper sx={{ mb: 1, fontWeight: 700 }}>メールアドレス</Paper>
              <Paper
                component="form"
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid rgba(0, 0, 0, 0.15)',
                  width: '100%'
                }}
              >
                <InputBase
                  sx={{
                    ml: 1,
                    flex: 1
                  }}
                  placeholder="メールアドレスを入力"
                  {...register('email')}
                />
              </Paper>
            </div>
            <Paper
              sx={{
                pl: 2
              }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: 60,
                  height: 40,
                  borderRadius: 1,
                  fontWeight: 500,
                  background: '#2D67F6',
                  '&:hover': {
                    background: '#2D67F6'
                  }
                }}
              >
                検索
              </Button>
            </Paper>
          </div>
        </form>
        <Paper className="w-[26%] text-right">
          <Button
            startIcon={<span>+</span>}
            variant="contained"
            onClick={() => {
              setTypeAction(TypeAction.CREATE);
              handleOpen();
            }}
            sx={{
              width: 110,
              height: 40,
              borderRadius: 1,
              fontWeight: 500,
              background: '#2D67F6',
              '&:hover': {
                background: '#2D67F6'
              }
            }}
          >
            新規作成
          </Button>
        </Paper>
      </div>
      {isLoading && isFetching ? (
        <LoadingSpin />
      ) : (
        <Paper sx={{ mt: 3 }}>
          <Paper sx={{ mb: 1, fontWeight: 700 }}>アカウント一覧</Paper>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">名前</StyledTableCell>
                  <StyledTableCell align="left">メールアドレス</StyledTableCell>
                  <StyledTableCell align="center">操作</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataCustom?.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell width={'37%'}>{row.name}</StyledTableCell>
                    <StyledTableCell align="left" width={'38%'}>
                      {row.email}
                    </StyledTableCell>
                    <StyledTableCell align="center" width={'25%'}>
                      <Paper
                        sx={{
                          display: 'flex',
                          gap: 1,
                          justifyContent: 'center'
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            width: 68,
                            height: 30,
                            borderRadius: 1,
                            fontWeight: 500,
                            background: '#2D67F6',
                            '&:hover': {
                              background: '#2D67F6'
                            }
                          }}
                          onClick={() => {
                            setDetailAccount(row);
                            setTypeAction(TypeAction.DETAIL);
                            handleOpen();
                          }}
                        >
                          {TITLE.DETAIL}
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            width: 68,
                            height: 30,
                            borderRadius: 1,
                            fontWeight: 500,
                            background: '#2D67F6',
                            '&:hover': {
                              background: '#2D67F6'
                            }
                          }}
                          onClick={() => {
                            setDetailAccount(row);
                            setTypeAction(TypeAction.EDIT);
                            handleOpen();
                          }}
                        >
                          {TITLE.EDIT}
                        </Button>
                        <Button
                          variant="contained"
                          sx={{
                            width: 68,
                            height: 30,
                            borderRadius: 1,
                            fontWeight: 500,
                            background: '#d82121',
                            '&:hover': {
                              background: '#d82121'
                            }
                          }}
                          onClick={() => {
                            setDetailAccount(row);
                            handleOpenDelete();
                          }}
                        >
                          {TITLE.DELETE}
                        </Button>
                      </Paper>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {dataCustom?.length !== null  && dataCustom?.length === 0 && (
              <div className="text-center p-[10px] w-full">
                データがありません。
              </div>
            )}
          </TableContainer>
          {rowLength > 0 && (
            <PaginationCustom
              currentPagePagination={currentPagePagination}
              params={params}
              setParams={setParams}
              currentCount={currentCount}
            />
          )}
        </Paper>
      )}
      <AccountCUD
        setTypeAction={setTypeAction}
        setOpen={setOpen}
        typeAction={typeAction}
        detailAccount={detailAccount}
        handleClose={handleClose}
        open={open}
        mutateCreateAccount={mutateCreateAccount}
        mutateEditAccount={mutateEditAccount}
        queryClient={queryClient}
      />
      <AccountDelete
        handleCloseDelete={handleCloseDelete}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        detailAccount={detailAccount}
        handleDeleteAccount={handleDeleteAccount}
      />
    </>
  );
}
