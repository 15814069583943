import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography
} from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
import { useLocation } from 'react-router-dom';
import { TypeAction } from 'src/sections/accountManagement';
import { TITLE } from 'src/constants/title';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
// //
// import AccountPopover from './AccountPopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 64;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  background: 'white',
  height: 64,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`
  }
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func
};

export default function Header({ onOpenNav, pageTitle }) {
  const location = useLocation();
  const isDetail = location.pathname.includes(TypeAction.DETAIL);
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' }
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box>
          <Typography
            variant="h5"
            style={{ color: 'black', fontSize: '24px', fontWeight: 700 }}
          >
            {pageTitle}
            {isDetail ? TITLE.DETAIL : TITLE.INQUIRY}
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1
          }}
        >
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
