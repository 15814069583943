import { Modal, Paper, Button } from '@mui/material';
import Box from '@mui/material/Box';
import { TITLE } from '../../../constants/title';
import { styled } from '@mui/material/styles';

const styleModalDelete = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 462,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  p: 2
};

const StyledModal = styled(Modal)(`
  .MuiPaper-root {
    box-shadow: none
  }
`);

export default function AccountDelete(props) {
  const {
    detailAccount,
    handleDeleteAccount,
    handleCloseDelete,
    setOpenDelete,
    openDelete
  } = props;

  return (
    <>
      <StyledModal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        width={462}
      >
        <Box sx={styleModalDelete}>
          <img
            src={`/assets/images/info.jpg`}
            alt="info"
            style={{
              width: '73px',
              height: '73px'
            }}
          />
          <Paper sx={{ textAlign: 'center', my: 2 }}>
            <div>本当に削除しますか？</div>
            <div>この操作は取り消せません。</div>
          </Paper>
          <Paper sx={{ display: 'flex', width: '100%', gap: 2, mt: 5 }}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: '100%',
                height: 48,
                borderRadius: '8px',
                fontWeight: 400,
                background: '#ffffff',
                color: '#000000',
                border: '1px solid #d9d9d9',
                boxShadow: 'none',
                '&:hover': {
                  background: 'transparent'
                }
              }}
              onClick={handleCloseDelete}
            >
              {TITLE.CANCEL}
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: '100%',
                height: 48,
                borderRadius: '8px',
                fontWeight: 400,
                background: '#d83535',
                boxShadow: 'none',
                '&:hover': {
                  background: '#d83535'
                }
              }}
              onClick={handleDeleteAccount}
            >
              {TITLE.DELETE}
            </Button>
          </Paper>
        </Box>
      </StyledModal>
    </>
  );
}
