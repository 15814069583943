// component
import { TITLE } from 'src/constants/title';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: TITLE.ACCOUNT_MANAGEMENT,
    path: '/sample/account-management',
    pathSub: '/sample/account-management',
    icon: icon('icon_menu_1')
  },
  {
    title: TITLE.USED_CAR_MARKET_INFORMATION,
    path: '/sample/used-car-market-information',
    pathSub: '/sample/used-car-market-information/detail/:id',
    icon: icon('icon_menu_2')
  },
  {
    title: TITLE.AUCTION_INFORMATION,
    path: '/sample/auction-information',
    pathSub: '/sample/auction-information/detail/:id',
    icon: icon('icon_menu_3')
  },

  {
    title: 'お得中古車抽出',
    path: '/sample/extracting-advantageous-used-cars',
    pathSub: '/sample/extracting-advantageous-used-cars',
    icon: icon('icon_menu_4')
  }
];

export default navConfig;
