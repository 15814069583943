import MainLayout from 'src/layouts/MainLayout';
import DashboardLayout from '../layouts/dashboard/index';
import { lazy } from 'react';
import { RouteI } from 'src/models/router';
import { RoutePath } from 'src/enums/routePath';
import { TITLE } from 'src/constants/title';

const LoginPage = import('../pages/Login');
const ResetPasswordPage = import('../pages/ResetPassword');
const UserPage = import('../pages/User');
const SamplePage05 = import('../pages/SamplePage05');
const SamplePage06 = import('../pages/SamplePage06');
const SamplePage07 = import('../pages/SamplePage07');
const SamplePage08 = import('../pages/SamplePage08');
const SamplePage09 = import('../pages/SamplePage09');
const SamplePage10 = import('../pages/SamplePage10');

export const routes: RouteI[] = [
  {
    path: RoutePath.LOGIN,
    Component: lazy(() => LoginPage)
  },
  {
    path: RoutePath.RESET_PASSWORD,
    Component: lazy(() => ResetPasswordPage)
  },
  {
    path: RoutePath.USER,
    Component: lazy(() => UserPage),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: '/sample/account-management',
    Component: lazy(() => SamplePage05),
    Layout: <DashboardLayout pageTitle={TITLE.ACCOUNT_MANAGEMENT} />,
    isPrivate: true
  },
  {
    path: '/sample/used-car-market-information',
    Component: lazy(() => SamplePage06),
    Layout: <DashboardLayout pageTitle={TITLE.USED_CAR_MARKET_INFORMATION} />,
    isPrivate: true
  },
  {
    path: '/sample/auction-information',
    Component: lazy(() => SamplePage07),
    Layout: <DashboardLayout pageTitle={TITLE.AUCTION_INFORMATION_TITLE} />,
    isPrivate: true
  },
  {
    path: '/sample/extracting-advantageous-used-cars',
    Component: lazy(() => SamplePage08),
    Layout: (
      <DashboardLayout pageTitle={TITLE.EXTRACTING_ADVANTAGEOUS_USED_CARS} />
    ),
    isPrivate: true
  },
  {
    path: '/sample/used-car-market-information/detail/:id',
    Component: lazy(() => SamplePage09),
    Layout: <DashboardLayout pageTitle={TITLE.USED_CAR_MARKET_INFORMATION} />,
    isPrivate: true
  },
  {
    path: '/sample/auction-information/detail/:id',
    Component: lazy(() => SamplePage10),
    Layout: <DashboardLayout pageTitle={TITLE.AUCTION_INFORMATION_TITLE} />,
    isPrivate: true
  }
];
