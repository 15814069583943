import { isLogged } from 'src/services/auth';
import { RoutePath } from 'src/enums/routePath';

/**
 *
 * @param location
 * @param authRole
 *
 * Just only add path parent of feature as /client (don't need create, update, detail)
 *
 * */

const matchPath = (path?: string, patterns?: string[]): boolean => {
  if (patterns && path) {
    return patterns.some((pattern) => {
      const regexPattern = new RegExp(
        `^${pattern.replace(/:[^\s/]+/g, '\\d+')}$`
      );
      return regexPattern.test(path);
    });
  }
  return false;
};

export const authMiddleware = (location: Location): boolean => {
  if (isLogged) {
    const authPath: { [key: string]: string[] } = {
      admin: [
        RoutePath.ACCOUNT_MANAGEMENT,
        RoutePath.DETAIL_USED_CAR_MARKET_INFORMATION,
        RoutePath.DETAIL_AUCTION_INFORMATION,
        RoutePath.USED_CAR_MARKET_INFORMATION,
        RoutePath.EXTRACTING_ADVANTAGEOUS_USED_CARS,
        RoutePath.AUCTION_INFORMATION,
      ]
    };

    const pathname = location.pathname;
    const isMatch = matchPath(pathname, authPath.admin);
    return isMatch;
  }

  return true;
};
