/* eslint-disable no-unused-vars */
export enum RoutePath {
  ALL = '/*',
  LOGIN = '/',
  RESET_PASSWORD = '/reset-password',
  NOT_FOUND = '/not-found',
  USER = '/user',
  ACCOUNT_MANAGEMENT = '/sample/account-management',
  USED_CAR_MARKET_INFORMATION = '/sample/used-car-market-information',
  DETAIL_USED_CAR_MARKET_INFORMATION = '/sample/used-car-market-information/detail/:id',
  AUCTION_INFORMATION = '/sample/auction-information',
  DETAIL_AUCTION_INFORMATION = '/sample/auction-information/detail/:id',
  EXTRACTING_ADVANTAGEOUS_USED_CARS= '/sample/extracting-advantageous-used-cars'
}
