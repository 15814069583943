export const TITLE = {
  CREATE: '新規作成',
  EDIT: '編集',
  DETAIL: '詳細',
  REGISTRATION: '登録',
  DELETE: '削除',
  CANCEL: 'キャンセル',
  INQUIRY: '照会',
  USED_CAR_MARKET_INFORMATION: '中古車市場情報',
  ACCOUNT_MANAGEMENT: 'アカウント管理',
  AUCTION_INFORMATION: 'オークション情報',
  AUCTION_INFORMATION_TITLE: '中古車オークション情報',
  EXTRACTING_ADVANTAGEOUS_USED_CARS: 'お得中古車抽出'
};
