import { combineReducers } from '@reduxjs/toolkit';
import loadingFullScreenSlice from './loadingFullScreen/loadingFullScreenSlice';
import authInfoSlice from './authInfoSlice';

const rootReducer = combineReducers({
  [loadingFullScreenSlice.name]: loadingFullScreenSlice.reducer,
  [authInfoSlice.name]: authInfoSlice.reducer,

});

export default rootReducer;
